<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('externalUserIrrigation.rating_list') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" v-b-modal.modal-4 @click="resetId">
              {{  $t('globalTrans.add_new') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(category_name)="data">
                      {{ data.item.category_name }}
                    </template>
                    <template v-slot:cell(farmer_id)="data">
                     {{ $n(data.item.farmer_id, { useGrouping: false }) }}
                    </template>
                    <template v-slot:cell(rating)="data">
                     {{ $n(data.item.rating, { useGrouping: false }) }}
                    </template>
                    <template v-slot:cell(action)="data">
                      <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                    </template>
                  </b-table>
                  <b-pagination
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <FormV :id="editItemId" :key="editItemId"/>
      </p>
    </b-modal>

  </b-container>
</template>
<script>
import FormV from './Form'
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { farmerRatingList, categoryToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    FormV
  },
  data () {
    return {
      search: {
        category_name: ''
      },
      ratinglist: [],
      districtList: [],
      upazilaList: [],
      getOrganizationlist: []
    }
  },
  computed: {
    formTitle () {
       return (this.editItemId === 0) ? this.$t('externalUserIrrigation.rating') + ' ' + this.$t('globalTrans.entry') : this.$t('externalUserIrrigation.rating') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('externalUserIrrigation.organization'), class: 'text-left' },
          { label: this.$t('org_pro_division.division'), class: 'text-left' },
          { label: this.$t('externalUserIrrigation.district'), class: 'text-left' },
          { label: this.$t('org_pro_upazilla.upazilla'), class: 'text-left' },
          { label: this.$t('externalUserIrrigation.feedback'), class: 'text-left' },
          { label: this.$t('externalUserIrrigation.rating'), class: 'text-left' },
          { label: this.$t('externalUserIrrigation.farmer'), class: 'text-left' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]
      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'org_name_bn' },
          { key: 'division_name_bn' },
          { key: 'distict_name_bn' },
          { key: 'upzila_name_bn' },
          { key: 'feedback_bn' },
          { key: 'rating' },
          { key: 'farmer_id' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'org_name' },
          { key: 'division_name' },
          { key: 'distict_name' },
          { key: 'upzila_name' },
          { key: 'feedback' },
          { key: 'rating' },
          { key: 'farmer_id' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    }
  },
  async created () {
    this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    remove (item) {
      this.changeStatus(irriSchemeServiceBaseUrl, categoryToggleStatus, item, 'irrigation', 'itemList')
    },
    loadData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(irriSchemeServiceBaseUrl, farmerRatingList, params).then(response => {
          if (response.success) {
             this.$store.dispatch('setList', this.formatData(response.data.data))
            this.paginationData(response.data)
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    formatData (data) {
      const formattedData = data.map(item => {
        const divisionData = this.$store.state.commonObj.divisionList.find(division => division.value === item.division_id && division.status === 0)
        const districtData = this.$store.state.commonObj.districtList.find(district => district.value === item.district_id && district.status === 0)
        const upazilaData = this.$store.state.commonObj.upazilaList.find(upazilla => upazilla.value === item.upazilla_id && upazilla.status === 0)
        const orgList = this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.find(organization => organization.value === item.org_id && organization.status === 0)
        const tmpBindingData = {
          division_name: divisionData !== undefined ? divisionData.text_en : '',
          division_name_bn: divisionData !== undefined ? divisionData.text_bn : '',
          distict_name: districtData !== undefined ? districtData.text_en : '',
          distict_name_bn: districtData !== undefined ? districtData.text_bn : '',
          upzila_name: upazilaData !== undefined ? upazilaData.text_en : '',
          upzila_name_bn: upazilaData !== undefined ? upazilaData.text_bn : '',
          org_name: orgList !== undefined ? orgList.text_en : '',
          org_name_bn: orgList !== undefined ? orgList.text_bn : ''
        }
        return Object.assign({}, item, tmpBindingData)
      })
      return formattedData
    }
  }
}
</script>
