<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="10" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                    <ValidationProvider name="Organiation Name" vid="org_id" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="org_id"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                        {{$t('org_pro.organization')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          v-model="rating.org_id"
                          :options="orgList"
                          id="organiation_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Division Name" vid="division_id" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="division"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                        {{$t('org_pro_division.division')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          v-model="rating.division_id"
                          :options="divisionList"
                          id="division_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="District Name" vid="district_id" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="district"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                        {{$t('org_pro_district.district')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          v-model="rating.district_id"
                          :options="districtList"
                          id="district_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Upazila" vid='upazilla_id' rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="upazila_id"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                          {{ $t('org_pro_upazilla.upazilla')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          v-model="rating.upazilla_id"
                          :options="upazilaList"
                          id="upazila_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  <ValidationProvider name="Feedback (En)" vid="feedback"  rules="required">
                        <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="remarks"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                        {{ $t('externalUserIrrigation.feedback_en')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-textarea
                            id="remarks"
                            v-model="rating.feedback"
                            placeholder=""
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-textarea>
                        <div class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="feedback (Bn)" vid="feedback_bn">
                        <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="feedback_bn"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                        {{ $t('externalUserIrrigation.feedback_bn')}}
                        </template>
                        <b-form-textarea
                            id="remarks"
                            v-model="rating.feedback_bn"
                            placeholder=""
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-textarea>
                        <div class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Rating" vid="rating" rules="required|max_value:5">
                        <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="rating"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                            {{ $t('externalUserIrrigation.rating')}}<span class="text-danger">*</span>
                        </template>
                        <b-form-input
                            type="number"
                            id="rating"
                            v-model="rating.rating"
                            placeholder=""
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                        </b-form-group>
                      </ValidationProvider>
                    <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { farmerRatingStore, farmerRatingUpdate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getRatingData()
      this.rating = tmp
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      totalPost: 0,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      rating: {
        org_id: 0,
        division_id: 0,
        district_id: 0,
        upazilla_id: 0,
        farmer_id: this.$store.state.Auth.authUser.id,
        feedback: '',
        feedback_bn: '',
        rating: ''
      },
      districtList: [],
      upazilaList: []
    }
  },
  computed: {
    orgList: function () {
      return this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList
    }
  },
  watch: {
    'rating.division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'rating.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    }
  },
  methods: {
    getRatingData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async register () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }

      if (this.rating.id) {
        result = await RestApi.putData(irriSchemeServiceBaseUrl, `${farmerRatingUpdate}/${this.id}`, this.rating)
      } else {
        result = await RestApi.postData(irriSchemeServiceBaseUrl, farmerRatingStore, this.rating)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('IrriConfig/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Farmer Id Already Exists',
          color: '#D6E09B'
        })
      }
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }
      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    }
  }
}
</script>
